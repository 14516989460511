<template>
  <article class="cases page__cases">
    <div class="cases__container">
      <h1 class="cases__title">Кейсы</h1>
      <VCases/>
      <div class="cases__button-wrapper">
        <a class="button cases__button-more button--outline button--small" href="#" role="button">
          <span class="button__caption">Показать больше кейсов</span>
        </a>
      </div>
    </div>
  </article>
</template>

<script>
import VCases from "@/components/cases/VCases";
export default {
  name: "CasesPage",
  components: {
    VCases
  }

}
</script>

<style scoped>

</style>