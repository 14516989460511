import { render, staticRenderFns } from "./CasesPage.vue?vue&type=template&id=af9d719e&scoped=true&"
import script from "./CasesPage.vue?vue&type=script&lang=js&"
export * from "./CasesPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af9d719e",
  null
  
)

export default component.exports